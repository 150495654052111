@import '@styles/variables.scss';

.footer {
  color: $total-white;
  background-color: $total-black;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 10px;
}

.text,
.contacts {
  text-align: left;
  flex-basis: 40%;
  margin: 20px 0;

  h3 {
    color: $accent;
  }
}

.contacts {
  h3 {
    margin-bottom: 20px;
  }
}

.icons {
  margin-top: 20px;

  .icon {
    margin-right: 10px;
  }
}

.icon path:hover {
  fill: $accent;
}

.copyright {
  flex-basis: 100%;
  border-top: $sub 1px solid;
  margin-top: 10px;
}

.idr {
  font-size: 12px;
  margin: 10px 0 0 0;
}
