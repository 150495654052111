@import '@styles/variables.scss';

.scrollTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: $accent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 99999;

  svg {
    margin-top: 11px;
    margin-left: 9px;
  }
}

.icon {
  display: block;
}
