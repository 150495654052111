@import './_variables.scss';
@import './buttons.scss';
@import './masonry.scss';

html,
body {
  padding: 0;
  margin: 0;
  background-color: $total-white;
  font-family:
    'Bitter',
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

a:focus {
  outline: 1px solid $accent;
}

a:hover {
  transition: all 0.3s ease-in;
  color: $accent-hover;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3 {
  margin: 0;
  font-family:
    'Work Sans',
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

h1 {
  font-size: $h1;
  font-weight: 800;
  line-height: 0.9;
  text-transform: uppercase;
  font-weight: 800;
}

h2 {
  font-size: $h2;
  font-weight: 800;
  line-height: 1.1;
}

h3 {
  font-size: $h3;
  font-weight: 800;
  line-height: 1.2;
}

p {
  font-size: $p;
  line-height: 1.7;
}

@media (max-width: 768px) {
  h1 {
    font-size: $h1-mob;
  }

  h2 {
    font-size: $h2-mob;
  }
}

.linkAsBtn {
  padding: 10px 30px;
  border: none;
  background-color: $accent;
  text-decoration: none;
  box-sizing: border-box;
  display: block;
  font-weight: bold;
  font-family:
    'Work Sans',
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

.linkAsBtn:hover {
  color: $total-white;
  background-color: $accent-hover;
  transition: all 0.3 ease-in;
}

.fullFlex {
  display: flex;
  height: 100%;
}

.heroContainer {
  max-width: 100%;
  height: 80vh;
}

.cursorPointer {
  cursor: pointer;
}

.screenReader {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}
