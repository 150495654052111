.masonryGrid {
  display: flex;
  width: auto;
}

.masonryColumn {
  flex: 1 0 auto;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  background-clip: padding-box;
}

.masonryColumn > div {
}
