@import '@styles/variables.scss';

.header {
  background-color: $total-white;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.navigation {
  display: flex;
  order: 2;
  flex-basis: 100%;
  justify-content: inherit;
  margin-top: 10px;
  flex-wrap: wrap;

  a {
    align-self: center;
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  a:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
}

.menu {
  align-self: center;
  order: 1;
}

.mobile {
  justify-content: center;
}

.active {
  font-weight: 700;
  color: $accent;
}

@media (min-width: 768px) {
  .navigation {
    flex-basis: auto;
    margin-top: 0;
    flex-wrap: nowrap;
  }

  .menu {
    display: none;
  }

  .navigation {
    a {
      margin-right: 20px;
      margin-bottom: 0;
    }

    a:last-child {
      margin-right: 0;
    }
  }
}
