.button {
  width: 100%;
  color: $total-white;
  text-align: center;
}

@media (min-width: 768px) {
  .button {
    width: max-content;
  }
}
